.sortb {
    height: 36px;
    width: 96px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    font-feature-settings: 'ss01'on;
    color: #3D3D3D;

}

.srch {
    height: 36px;
    width: 212px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    font-feature-settings: 'ss01'on;
    color: #3D3D3D;
    margin-left: 22px
}

.afrnch {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01'on;
    color: #12352F;
}

.rwidhtl {
    width: 998px;
    height: 77px;
    border-radius: 1px;
    border: solid 1px #E0E0E0;

}

.mrwidth {
    width: 1038px;
    height: 84px;
}

.fntdrk {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 0px;
    text-transform: uppercase;
    color: #3D3D3D;
}

.fntsmal {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3D3D3D;
}

.dots {
    font-size: 160%;
    line-height: 40%;
    color: black;
}

.paddingtops {
    padding-top: 8px;
}

.paddingtext {
    padding-left: 1%;
    margin-right: -2%;
}

.paddingfos {
    padding-left: 3%;
}

#scrollingup {
    height: 100%;
    width: 100%;
    border: 1px solidg #D9D9D9;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 420px;
}

.adhar-box {
    width: 448px;
    height: 154px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
}

.profile-box {
    width: 200px;
    height: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
}

.midspace {
    height: 499px;
    width: 1038px;
}